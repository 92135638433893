<template>
  <SettingsContainer :header="$t('message.contact-preferences.link')">
    <div class="md:w-1/2 md:mx-auto">
      <h5 class="mb-2">
        {{ $t("message.contact-preferences.heading") }}
      </h5>
      <p class="font-body2 mb-8">
        {{ $t("message.contact-preferences.subtext-2") }}
      </p>
      <div class="option">
        <Checkbox
          id="team-updates"
          v-model="preferences"
          :trueValue="$system_config.teamUpdatesYes"
          :falseValue="$system_config.teamUpdatesNo"
          :disabled="loading"
        />
        <label class="font-body" for="team-updates">{{
          $t("message.contact-preferences.team-updates")
        }}</label>
      </div>
      <div class="option">
        <Checkbox
          id="recommended-content"
          v-model="preferences"
          :trueValue="$system_config.recommendedContentYes"
          :falseValue="$system_config.recommendedContentNo"
          :disabled="loading"
        />
        <label class="font-body" for="recommended-content">
          {{ $t("message.contact-preferences.recommended-content") }}
        </label>
      </div>
      <div class="option">
        <Checkbox
          id="news-and-offers"
          v-model="preferences"
          :trueValue="$system_config.marketingContentYes"
          :falseValue="$system_config.marketingContentNo"
          :disabled="loading"
        />
        <label class="font-body" for="news-and-offers">
          {{ $t("message.contact-preferences.marketing-content") }}
        </label>
      </div>
    </div>
  </SettingsContainer>
</template>

<script>
import SettingsContainer from "@/components/settings/SettingsContainer.vue";
import Checkbox from "@/components/form/Checkbox.vue";
import { GET_MY_CONTACT_PREFERENCES } from "@/graphql/queries/user/userQueries.js";
import { PROFILE_BASIC_MUTATION } from "@/graphql/queries/user/userMutations.js";
import { loadContactPreferencesAttribute } from "@/utils/basicHelpers";

export default {
  name: "ContactPreferencesPage",
  components: {
    SettingsContainer,
    Checkbox
  },
  data() {
    return {
      preferences: [],
      currentPreferences: [],
      skipGetData: false,
      loading: true
    };
  },
  computed: {
    changesPending() {
      if (
        this.preferences &&
        this.currentPreferences &&
        this.preferences.length !== this.currentPreferences.length
      ) {
        return true;
      }
      try {
        this.currentPreferences.forEach(preference => {
          if (this.preferences.indexOf(preference) < 0) {
            throw true;
          }
        });
      } catch (val) {
        return val;
      }
      return false;
    }
  },
  watch: {
    preferences() {
      if (this.changesPending) {
        this.updatePreferences();
      }
    }
  },
  apollo: {
    myContactPreferences: {
      query: GET_MY_CONTACT_PREFERENCES,
      update(data) {
        const translatedContactPreferences = loadContactPreferencesAttribute(
          data.getMyProfile.contactPreferences
        );
        this.preferences = [...translatedContactPreferences];
        this.currentPreferences = [...translatedContactPreferences];
        this.skipGetData = true;
        this.loading = false;
        return translatedContactPreferences;
      },
      //test if this property is needed
      fetchPolicy: "no-cache",
      errorPolicy: "all",
      skip() {
        return this.skipGetData;
      }
    }
  },
  methods: {
    updatePreferences() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$apollo.mutate({
        mutation: PROFILE_BASIC_MUTATION,
        variables: {
          newProfile: {
            contactPreferences: this.preferences.join(",")
          }
        },
        update: () => {
          this.currentPreferences = [...this.preferences];
          this.loading = false;
          this.$toast.open({
            type: "success",
            message: this.$t("message.updated"),
            position: this.$store.getters["general/getToastPosition"],
            duration: 3000
          });
        },
        error() {
          this.$toast.open({
            type: "error",
            message: this.$t("message.error"),
            position: this.$store.getters["general/getToastPosition"],
            duration: 3000
          });
        }
      });
    }
  }
};
</script>
<style lang="postcss" scoped>
.option {
  @apply flex flex-row mt-6 items-center;
  label {
    @apply ml-4;
  }
}
</style>
