<template>
  <div>
    <BackButton
      v-if="isDesktop"
      type="dark"
      @click="$router.go(-1)"
      text="Back"
      class="my-7"
    />
    <div class="flex flex-col w-full relative md:bg-white md:rounded-md">
      <div class="px-4 md:px-16 md:py-20">
        <div
          class="relative flex items-center"
          :class="isDesktop ? 'mb-12' : 'mt-14 mb-8'"
        >
          <BackButton
            v-if="!isDesktop"
            type="dark"
            @click="$router.go(-1)"
            class="absolute"
          />
          <h3 class="capitalize text-center inline mx-auto">
            {{ header }}
          </h3>
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/shared/BackButton.vue";
import { mapGetters } from "vuex";
export default {
  name: "SettingsContainer",
  components: {
    BackButton
  },
  props: {
    header: {
      type: String,
      required: true
    },
    centerDesktopHeader: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters("general", ["isDesktop"])
  }
};
</script>

<style></style>
